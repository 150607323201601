// src/redux/store.js
import { configureStore } from "@reduxjs/toolkit";
import profileReducer from "./slices/profileFetch";
import schedulePost from "./slices/schedulePost";

const store = configureStore({
  reducer: {
    profile: profileReducer,
    schedule: schedulePost,
  },
});

export default store;
