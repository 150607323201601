import React, { createContext } from "react";
import { io } from "socket.io-client";

const SOCKET_URL = "https://staging.yooii.com";
let socket = io(SOCKET_URL, {
  path: "/socket.io/",
  jsonp: false,
  upgrade: false,
  reconnection: true,
});

socket = socket.connect();

const SocketContext = createContext();

export { SocketContext, socket };
