import React, { useEffect, useState } from "react";

const Scrolltop = ({ isVisible }) => {
  const scrollToTop = () => {
    document.getElementById('scrollDiv').scrollTo({
      top: 0,
      behavior: "smooth"
    })
  };

  return (
    <div className="fixed bottom-5 right-5 z-50">
      {isVisible && (
        <button
          onClick={scrollToTop}
          className="bg-Red text-white rounded-full w-10 h-10 flex items-center justify-center shadow-lg hover:bg-red-700 focus:outline-none"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-arrow-up"
          >
            <line x1="12" y1="19" x2="12" y2="5" />
            <polyline points="5 12 12 5 19 12" />
          </svg>
        </button>
      )}
    </div>
  );
};

export default Scrolltop;
