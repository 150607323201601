const URL = {
  LOGIN: "/login/",
  FORGOT_PASSWORD: "/forgot-password/",
  OTP_VERIFY: "/verify-otp/",
  RESET_PASSWORD: "/reset-password/",
  USER_ADMIN_PROFILE: "/user-admin-profile/",
  UPLOAD_POST: "/upload-post/",
  USER_POSTS: "user-posts/",
  SCHEDULED_POST: "/scheduled-posts/",
  USER_MANAGEMENT_API: "/api-user/",
  EDIT_PROFILE: "/edit-profile/",
  DELETE_POST: "/delete-post/",
  DELETE_USER: "/delete-profile/",
  POST_MANAGEMENT_GET: "/api-post/",
  ADMIN_POST_DELETE: "/delete-post-admin/",
  ADMIN_USER_DELETE: "/delete-user-admin/",
  ADMIN_DASHBOARD: "/admim-dashboard/",
  ANALYTICS_DATA: "/analytics-data/",
  SHARE_PROFILE: "/share-profile/",
  REMOVE_SCHEDULED_POSTS: "/remove-scheduled-posts/",
};

module.exports = { URL };
