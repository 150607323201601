import { Dialog } from "@mui/material";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { IntlContext } from "src/App";
import apiInstance from "src/helpers/Axios/axiosINstance";
import siteConstant from "src/helpers/constant/siteConstant";
import { URL } from "src/helpers/constant/Url";
import { clearStorage, fetchFromStorage } from "src/helpers/context/storage";
import { setApiMessage } from "src/helpers/context/toaster";

const DeleteDialogueModel = ({ open, handleDialogClose }) => {
  const navigate = useNavigate();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const userData = fetchFromStorage(siteConstant?.INDENTIFIERS?.USERDATA);

  const DeleteProfile = async () => {
    try {
      const { status, data } = await apiInstance.get(URL.DELETE_USER);
      if (data) {
        if (data?.status) {
          clearStorage();
          navigate("/sign-in");
          setApiMessage("success", data?.message);
          console.log(setApiMessage("success".data?.message));
        }
      }
    } catch (error) {
      console.log("Error fetching profile:", error);
      handleDialogClose();
      setApiMessage("error", error?.message);
    } finally {
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      className="bg-transparent font-Montserat p-0"
    >
      <div className="bg-white p-0 rounded-xl shadow-lg w-full max-w-md overflow-hidden">
        <img
          src={intlContext?.socialIcons?.DELETE_USER_BG}
          alt={localesData?.USER_WEB?.DELETE_USER_BG}
          className="w-full sm:h-36 object-cover"
        />
        <div className="p-7">
          <div className="flex flex-col gap-3 items-center">
            <div className="sm:mb-4 flex flex-row">
              <img
                src={intlContext?.socialIcons?.DELETE_USER_INFO}
                alt={localesData?.USER_WEB?.DELETE_USER_INFO}
                className="sm:w-26 sm:h-26"
              />
              <p className="text-[13px] sm:text-[16px] ms-2 text-Red font-bold">
                {localesData?.USER_WEB?.ALL_DATA_WILL_BE}
              </p>
            </div>
            <p className="text-[11.5px] sm:text-[14px] mb-4 text-center">
              {localesData?.USER_WEB?.DELETE_LONG_MSG}
            </p>
            <div className="flex space-x-6 sm:space-x-12 ">
              <button
                className="text-Red py-1.5 sm:py-3 sm:w-32 w-28 text-[13px] sm:text-[15px] rounded-xl hover:bg-profileCardBG font-semibold border border-pink-100"
                onClick={() => handleDialogClose()}
              >
                {localesData?.USER_WEB?.CANCEL}
              </button>
              <button
                className="bg-Red text-white  py-1.5 sm:py-3 sm:w-32 w-28 text-[13px] sm:text-[15px] rounded-xl hover:bg-red-600 font-semibold"
                onClick={() => DeleteProfile()}
              >
                {localesData?.USER_WEB?.DELETE}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default DeleteDialogueModel;
