import React, { Suspense, useContext, useEffect, useState } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { FaUserAlt, FaCog, FaTrashAlt, FaSignOutAlt } from "react-icons/fa"; // Import icons from react-icons
import logo from "../../../src/assets/images/logo.svg";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Avatar,
  Menu,
  MenuItem,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Link,
  CssBaseline,
  Tooltip,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "../layouts.css";
import {
  clearStorage,
  fetchFromStorage,
  saveToStorage,
} from "src/helpers/context/storage";
import siteConstant from "src/helpers/constant/siteConstant";
import { IntlContext } from "src/App";
import navIcon from "src/assets/images/navbar-button.svg";
import styled from "@emotion/styled";
import Scrolltop from "../ScrollTop/Scrolltop";
import DeleteDialogueModel from "src/views/admin/common/deleteDialogue";
import Android12Switch from "src/views/components/UploadPost/socialswitch";
import { useSelector } from "react-redux";
import { BorderRight } from "@mui/icons-material";
const UploadPost = React.lazy(() => import("src/views/components/UploadPost"));

const drawerWidth = 73;
const iconDrawerWidth = 66;
const collapsedDrawerWidth = 83;
const expandedDrawerWidth = 236;

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "red",
    color: "white",
    fontSize: "14px",
    fontWeight: "600",
  },
  [`& .MuiTooltip-arrow`]: {
    color: "red",
  },
});

const ResponsiveIcon = styled("img")(({ theme }) => ({
  height: "2rem",
  width: "2rem",
  [theme.breakpoints.up("sm")]: {
    height: "2.25rem",
    width: "2.25rem",
  },
  [theme.breakpoints.up("lg")]: {
    height: "3.25rem",
    width: "3.25rem",
  },
}));

const theme = createTheme({
  transitions: {
    create: (props, options) => {
      if (!Array.isArray(props)) {
        return "";
      }
      return props
        .map(
          (prop) =>
            `${prop} ${options.duration}ms ${options.easing} ${options.delay}ms`
        )
        .join(",");
    },
    easing: {
      sharp: "cubic-bezier(0.4, 0, 0.6, 1)",
    },
    duration: {
      enteringScreen: 225,
      leavingScreen: 195,
    },
    delay: 150,
  },
  spacing: (factor) => `${0.25 * factor}rem`,
});

const Sidebar = () => {
  const rProfile = useSelector((state) => state?.profile?.data);
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [currentTab, setCurrentTab] = useState(
    fetchFromStorage(siteConstant?.INDENTIFIERS.currentTab)
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [sidebarProps, setSidebarProps] = useState([]);
  const [userData, setuserData] = useState(
    fetchFromStorage(siteConstant?.INDENTIFIERS?.USERDATA)
  );
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const firstName = userData?.name?.split(" ")[0];
  const [isScrolled, setIsScrolled] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isChecked, setIsChecked] = useState(
    currentTab === localesData?.ADMIN_TAB
  );

  const handleScroll = () => {
    const scrollDiv = document.getElementById("scrollDiv");
    setIsScrolled(scrollDiv.scrollTop > 0);
  };

  const [isVisible, setIsVisible] = useState(false);
  // const username = localStorage.getItem("username");
  // const profileImage = localStorage.getItem("profile_image");
  // const name = localStorage.getItem("name");

  const toggleVisibility = () => {
    const scrollDiv = document.getElementById("scrollDiv");
    if (scrollDiv.scrollTop > 200) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    const scrollDiv = document.getElementById("scrollDiv");
    scrollDiv?.addEventListener("scroll", handleScroll);
    scrollDiv?.addEventListener("scroll", toggleVisibility);
    return () => {
      scrollDiv?.removeEventListener("scroll", handleScroll);
      scrollDiv?.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  useEffect(() => {
    if (location?.pathname?.includes("/admin")) {
      setCurrentTab(localesData?.ADMIN_TAB);
    } else {
      setCurrentTab(localesData?.USER_TAB);
    }
  }, []);

  useEffect(() => {
    const storedUser = fetchFromStorage(siteConstant?.INDENTIFIERS?.USERDATA);
    setuserData(storedUser);
  }, [rProfile]);

  useEffect(() => {
    const adminSidebarItems = [
      {
        id: 1,
        name: `Hi, ${firstName}`,
        link: "/profile",
        logo: (
          <img
            alt="User Photo"
            className={`h-10 w-10 rounded-[14px] border-[2px] p-1 ${
              location.pathname === "/profile"
                ? "border-white border-[2px]"
                : "border-Red"
            }`}
            src={
              userData?.profile_image || siteConstant.SOCIAL_ICONS.DUMMY_PROFILE
            }
          />
        ),
      },
      {
        id: 2,
        name: "Dashboard",
        link: "/admin/dashboard",
        logo: (
          <img
            alt="Dashboard"
            className="h-10 w-10 p-2 bg-white rounded-[14px]  "
            src={siteConstant.ICONS.Dashboard_Icon}
          />
        ),
      },
      {
        id: 3,
        name: "User Management",
        link: "/admin/user-management",
        logo: (
          <img
            alt="Dashboard"
            className="h-10 w-10 p-2 bg-white rounded-[14px]  "
            src={siteConstant.ICONS.User_Icon}
          />
        ),
      },
    ];
    const userSidebarItems = [
      {
        id: 1,
        name: `Hi, ${firstName}`,
        link: "/profile",
        logo: (
          <img
            alt="User Photo"
            className={`h-10 w-10 rounded-[14px] border-[2px] p-1 ${
              location.pathname === "/profile"
                ? "border-white border-[2px]"
                : "border-red-500"
            }`}
            src={
              userData?.profile_image || siteConstant.SOCIAL_ICONS.DUMMY_PROFILE
            }
          />
        ),
      },
      {
        id: 2,
        name: "Dashboard",
        link: "/dashboard",
        logo: (
          <img
            alt="Dashboard"
            className="h-10 w-10 p-2 bg-white rounded-[14px]  "
            src={siteConstant.ICONS.Dashboard_Icon}
          />
        ),
      },
      {
        id: 3,
        name: "Upload Post",
        action: "uploadPost",
        logo: (
          <img
            alt="Dashboard"
            className="h-10 w-10 p-2 bg-white rounded-[14px]  "
            src={siteConstant.ICONS.Add_Icon}
          />
        ),
      },
      {
        id: 4,
        name: "Scheduled Post",
        link: "/scheduled-post",
        logo: (
          <img
            alt="scheduledicon"
            className="h-10 w-10 p-2 bg-white rounded-[14px]  "
            src={siteConstant.ICONS.Time_Icon}
          />
        ),
      },
    ];
    if (currentTab == localesData?.ADMIN_TAB) {
      setSidebarProps(adminSidebarItems);
    } else {
      setSidebarProps(userSidebarItems);
    }
  }, [userData, currentTab, firstName, location.pathname]);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleUploadPostOpen = () => {
    setIsUploadDialogOpen(true);
  };

  const handleUploadPostClose = () => {
    setIsUploadDialogOpen(false);
  };

  const logout = () => {
    clearStorage();
    navigate("/sign-in", { replace: true });
  };

  const switchTab = () => {
    if (currentTab === localesData?.ADMIN_TAB) {
      saveToStorage(
        siteConstant?.INDENTIFIERS.currentTab,
        localesData?.USER_TAB
      );
      setCurrentTab(localesData?.USER_TAB);
      navigate("/dashboard");
      setIsChecked(false);
      handleMenuClose();
    } else {
      saveToStorage(
        siteConstant?.INDENTIFIERS.currentTab,
        localesData?.ADMIN_TAB
      );
      setCurrentTab(localesData?.ADMIN_TAB);
      navigate("/admin/dashboard");
      setIsChecked(true);
      handleMenuClose();
    }
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              backdropFilter: "blur(10px)",
              boxShadow: "none",
              backgroundColor: isScrolled
                ? "rgba(255, 255, 255, 0.7)"
                : "transparent",
              borderBottom: "1px solid rgb(220,220,220)",
              transition:
                "background-color 0.3s ease, backdrop-filter 0.3s ease",
            }}
            className={`header ${isScrolled ? "header-blur" : ""}`}
          >
            <Toolbar className="flex justify-between px-3 font-Montserrat lg:px-5 lg:pl-3 bg-primarBG">
              <Box className="flex items-center">
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={handleDrawerToggle}
                  sx={{
                    display: drawerOpen ? "none" : "block",
                    height: "52px",
                    width: "52px",
                    marginBottom: {
                      xs: "2px",
                      sm: "4px",
                      md: "5px",
                      lg: "13px",
                    },
                  }}
                >
                  <ResponsiveIcon src={navIcon} />
                </IconButton>

                <div href="https://yooii.com" className="flex">
                  <div className=" flex items-center justify-between w-full xl:w-[250px] ">
                    {drawerOpen && (
                      <>
                        <img
                          src={logo}
                          alt="Yooii"
                          className="h-12 w-12 sidebar:w-[60px] sidebar:h-[60px] hidden sidebar:block cursor-pointer "
                        />
                        <img
                          alt="Sidebar Icon"
                          src={siteConstant.SOCIAL_ICONS.SIDE_BAR_ARROW_ICON}
                          className={`cursor-pointer w-8 mx-2 border-dark-purple lg:me-7
         border-2 rounded-full ${!drawerOpen && "rotate-180"}`}
                          onClick={() => handleDrawerToggle()}
                        />
                        {/* <IoIosArrowDropleft
                          onClick={handleDrawerToggle}
                          className=" text-Red  cursor-pointer ml-auto rounded-sm "
                          style={{
                            fontSize: "40px",
                          }}
                        /> */}
                      </>
                    )}
                  </div>
                </div>
                <div className="me-6 sm:me-20 md:ms-28">
                  <form class="w-[120px] sm:w-full ">
                    <label
                      for="default-search"
                      class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
                    >
                      {localesData?.USER_WEB?.SEARCH}
                    </label>
                    <div class="relative ">
                      <div class="absolute inset-y-0 start-0 flex items-center ps-2 pointer-events-none">
                        <svg
                          class="w-4 h-4 text-gray-400 dark:text-gray-400"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 20 20"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                          />
                        </svg>
                      </div>
                      <input
                        type="search"
                        id="default-search"
                        className="shadow-sm block nav-inp md:w-72 sm:p-2.5 bg-white ps-7 sm:ps-10 text-sm text-gray-900 border-none rounded-full placeholder-gray-400 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Search..."
                        required
                      />
                    </div>
                  </form>
                </div>
              </Box>

              <Box className="flex items-center pe-0 ">
                <p className="mb-2 sm:mb-0 md:mb-3 text-3xl md:text-5xl text-black font-Yooiilogo me-3 sm:pb-2 lg:pb-0">
                  {localesData?.USER_WEB?.YOOII}
                </p>
                <IconButton
                  onClick={handleMenuOpen}
                  className="flex text-sm bg-gray-500 rounded-full focus:ring-gray-300 dark:focus:ring-gray-600"
                >
                  <img
                    src={
                      userData?.profile_image ||
                      siteConstant.SOCIAL_ICONS.DUMMY_PROFILE
                    }
                    alt="User Photo"
                    className="h-10 w-10 rounded-2xl border-[2px] border-Red p-1"
                  />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  className="mt-2"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <div className="">
                    <MenuItem
                      sx={{
                        "&:hover": {
                          backgroundColor: "#fbdede",
                        },
                      }}
                    >
                      <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="user"
                      >
                        <img
                          src={
                            userData?.profile_image ||
                            siteConstant.SOCIAL_ICONS.DUMMY_PROFILE
                          }
                          alt="User Photo"
                          className="h-7 w-7 rounded-[12px] border border-Red p-[2px]"
                        />
                      </IconButton>
                      <p
                        variant="h1"
                        className="  text-sm font-normal font-Montserat text-black"
                        onClick={() => {
                          navigate("/profile");
                          handleMenuClose();
                        }}
                      >
                        {userData?.name}
                      </p>
                    </MenuItem>
                    <p className="border-b h-1 w-full bg-gray-50"></p>
                    {userData?.is_admin && (
                      <MenuItem
                        // onMouseEnter={handleMouseEnter}
                        // onMouseLeave={handleMouseLeave}
                        sx={{
                          "&:hover": {
                            backgroundColor: "#fbdede",
                            color: "red",
                          },
                        }}
                        onClick={switchTab}
                      >
                        <IconButton
                          edge="start"
                          color="inherit"
                          aria-label="switch"
                        >
                          <img
                            className="h-4 w-4 ms-1"
                            src={siteConstant.SOCIAL_ICONS.USER}
                          />
                        </IconButton>
                        <p className="text-sm font-semibold font-Montserat hover:text-Red ms-[2px]">
                          {localesData?.USER_WEB?.SWITCH} (
                          {currentTab === localesData?.ADMIN_TAB
                            ? localesData?.USER_TAB
                            : localesData?.ADMIN_TAB}
                          )
                        </p>
                        <div className="ps-4 ">
                          <Android12Switch
                            sx={{
                              width: 32,
                              height: 18,
                              "& .MuiSwitch-thumb": {
                                width: 14,
                                height: 14,
                              },
                              "& .MuiSwitch-track": {
                                borderRadius: 13,
                              },
                            }}
                            checked={isChecked}
                          />
                        </div>
                      </MenuItem>
                    )}

                    <MenuItem
                      sx={{
                        "&:hover": {
                          backgroundColor: "#fbdede",
                          color: "red",
                        },
                      }}
                      onClick={() => {
                        setOpenDelete(!openDelete);
                        handleMenuClose();
                      }}
                    >
                      <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="delete"
                      >
                        <img
                          className="h-4 w-4 ms-1 text-black "
                          src={siteConstant.SOCIAL_ICONS.DELETE}
                        />
                      </IconButton>
                      <p className="text-sm font-semibold font-Montserat ms-[2px]">
                        {localesData?.USER_WEB?.DELETE_ACCOUNT}
                      </p>
                    </MenuItem>
                    <p className="border-b h-1 w-full bg-gray-50"></p>
                    <MenuItem
                      sx={{
                        "&:hover": {
                          backgroundColor: "#fbdede",
                        },
                      }}
                      onClick={logout}
                    >
                      <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="sign-out"
                      >
                        <img
                          className="h-4 w-4 ms-1 "
                          src={siteConstant.SOCIAL_ICONS.SIGN_OUT}
                        />
                      </IconButton>
                      <p className="text-sm font-semibold font-Montserat ms-[2px]">
                        {localesData?.USER_WEB?.SIGN_OUT}
                      </p>
                    </MenuItem>
                  </div>
                </Menu>
              </Box>
            </Toolbar>
          </AppBar>

          <Drawer
            variant="persistent"
            style={{ fontFamily: "Montserrat sans-serif" }}
            open={drawerOpen}
            onClose={handleDrawerToggle}
            className="sm:fixed lg:hidden font-Montserat"
            sx={{
              display: { xs: "block", lg: "none" },
              "& .MuiDrawer-paper": {
                width: drawerOpen ? iconDrawerWidth : drawerWidth,
                boxSizing: "border-box",
                marginTop: {
                  md: "60px",
                  sm: "57px",
                  xs: "55px",
                },
                transition: "width ease-in-out 0.225s",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              },
            }}
          >
            {/* small */}
            <Box className="h-full px-2 p-2 pb-5  font-Montserat bg-primarBG overflow-y-auto ">
              <List>
                {sidebarProps?.map((sidebar) => (
                  <ListItem
                    key={sidebar.name}
                    button
                    onClick={() =>
                      sidebar.action === "uploadPost"
                        ? handleUploadPostOpen()
                        : navigate(sidebar.link)
                    }
                    selected={location.pathname === sidebar.link}
                    sx={{
                      marginBottom: "4px",
                      padding: "5px 5px",
                      fontFamily: "Sacramento",
                      borderRadius: "8px",
                      color: "rgba(255, 29, 29, 1)",
                      justifyContent: "center",
                      width: "100%",
                      minHeight: "auto",
                      "&:hover": {
                        backgroundColor: "rgba(255, 0, 0, 0.1)",
                      },
                      "&.Mui-selected, &.Mui-selected:hover": {
                        backgroundColor: "rgba(255, 29, 29, 1)",
                      },
                    }}
                  >
                    <CustomTooltip
                      title={sidebar?.name}
                      placement="right"
                      arrow
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: "auto",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          height: "auto",
                        }}
                      >
                        {sidebar.logo}
                      </ListItemIcon>
                    </CustomTooltip>

                    <ListItemText
                      primary={sidebar.name}
                      sx={{
                        display: { xs: "none", lg: "none" },
                        color:
                          location.pathname === sidebar.link
                            ? "white"
                            : "black",
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Drawer>

          <Drawer
            variant="permanent"
            className="font-Montserat"
            style={{ fontFamily: "Montserrat sans-serif" }}
            open
            sx={{
              display: { xs: "none", lg: "block" },
              "& .MuiDrawer-paper": {
                width: drawerOpen ? expandedDrawerWidth : collapsedDrawerWidth,
                boxSizing: "border-box",
                marginTop: "0px",
                position: "fixed",
                transition: theme.transitions.create("width", {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.enteringScreen,
                }),

                // display: "flex", // Added
                // flexDirection: "column", // Added
                // alignItems: "center", // Added
                // justifyContent: "center", // Added
              },
            }}
          >
            <Toolbar />
            <Box className="h-full  pt-4 font-Montserat bg-primarBG">
              <List className="whitespace-nowrap">
                {sidebarProps?.map((sidebar) => (
                  <div key={sidebar?.id} className="py-2 ps-3 pe-4">
                    <ListItem
                      button
                      onClick={() =>
                        sidebar.action === "uploadPost"
                          ? handleUploadPostOpen()
                          : navigate(sidebar.link)
                      }
                      selected={location.pathname === sidebar.link}
                      sx={{
                        marginBottom: "4px",
                        padding: "5px 8px",
                        borderRadius: "8px",
                        color: "rgba(255, 29, 29, 1)",
                        boxShadow:
                          sidebar.id === 1
                            ? "0px 2px 8px rgba(0, 0, 0, 0.1)"
                            : "none",
                        transition: "background-color 0.6s ease",

                        "&:hover": {
                          backgroundColor: "rgba(255, 0, 0, 0.1)",
                          transition: "background-color 0.6s ease",
                        },
                        "&.Mui-selected, &.Mui-selected:hover": {
                          backgroundColor: "rgba(255, 29, 29, 1)",
                          transition: "background-color 0.6s ease",
                        },
                      }}
                    >
                      <ListItemIcon>{sidebar?.logo}</ListItemIcon>
                      <ListItemText
                        primary={sidebar?.name}
                        sx={{
                          display: drawerOpen ? "block" : "none",
                          color:
                            location.pathname === sidebar?.link
                              ? "white"
                              : "Red",
                          textAlign: "left",
                          margin: "-3px",
                          fontWeight: "600",
                          fontSize: "14.5px",
                        }}
                      />
                    </ListItem>
                  </div>
                ))}
                <Suspense>
                  {isUploadDialogOpen && (
                    <UploadPost
                      open={isUploadDialogOpen}
                      onClose={handleUploadPostClose}
                    />
                  )}
                </Suspense>
              </List>
            </Box>
          </Drawer>

          <Box
            className="bg-[#f3eff4]"
            component="main"
            sx={{
              flexGrow: 1,
              padding: 3,
              marginLeft: {
                xs: drawerOpen ? "48px" : "0px",
                lg: drawerOpen
                  ? `${expandedDrawerWidth}px`
                  : `${collapsedDrawerWidth}px`,
              },
              width: {
                xs: drawerOpen ? `calc(100% - 240px)` : `calc(100% - 60px)`,
                lg: drawerOpen
                  ? `calc(100% - ${expandedDrawerWidth}px)`
                  : `calc(100% - ${collapsedDrawerWidth}px)`,
              },
              transition: "margin 0.225s",
            }}
          >
            <Toolbar />
            <div
              className="overflow-auto h-screen font-Montserat pb-10"
              id="scrollDiv"
              style={{
                width: "calc(100% - -10px)",
              }}
            >
              <Outlet />
              <Scrolltop isVisible={isVisible} />
            </div>
          </Box>
        </Box>
      </ThemeProvider>
      <DeleteDialogueModel
        open={openDelete}
        handleDialogClose={() => setOpenDelete(!openDelete)}
      />
    </>
  );
};

export default Sidebar;
