import axios from "axios";
import { clearStorage, fetchFromStorage } from "../context/storage";
import siteConstant from "../constant/siteConstant";

const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

apiInstance.interceptors.request.use((config) => {
  const token = fetchFromStorage(siteConstant?.INDENTIFIERS?.USERDATA)?.token;
  const clonedConfig = config;
  if (token) {
    clonedConfig.headers = {
      Authorization: `Bearer ${token}`,
      ...clonedConfig.headers,
      "Content-Type":
        clonedConfig.headers["Content-Type"] || "multipart/form-data",
    };
  } else {
    clonedConfig.headers = {
      "Content-Type": "multipart/form-data",
      ...clonedConfig.headers,
    };
  }
  return clonedConfig;
});

apiInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      const { status } = error.response;
      if (status >= 500 && status <= 599) {
        console.error(`Server Error ${status}: ${error.response.statusText}`);
        window.location.href = "/bad-gateway";
      }else if (status === 403){
        console.error("Forbidden: Unauthorized access");
        clearStorage()
        window.location.href = "/login";
      }
       else if (status === 400) {
        console.error("Error:", error.response.data.message);
      }
    }
    return Promise.reject(error.response?.data ? error.response.data : error);
  }
);

export default apiInstance;
