import React from "react";
import { Outlet } from "react-router-dom";
import siteConstant from "src/helpers/constant/siteConstant";

function AuthLayout() {
  return (
    <>
      <img
        src={siteConstant.SOCIAL_ICONS.YOOIILOGO}
        alt="logo"
        className="w-20 h-20 lg:w-28 lg:h-28 mx-4 absolute"
      />
      <Outlet />
    </>
  );
}

export default AuthLayout;
