import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiInstance from "src/helpers/Axios/axiosINstance";
import { URL } from "src/helpers/constant/Url";
import siteConstant from "src/helpers/constant/siteConstant";
import { fetchFromStorage, saveToStorage } from "src/helpers/context/storage";

// Define the async thunk to fetch the user profile
export const fetchProfile = createAsyncThunk(
  "profile/fetchProfile",
  async (_, { rejectWithValue }) => {
    try {
      const { status, data } = await apiInstance.get(URL.USER_ADMIN_PROFILE);
      const storedData = fetchFromStorage(siteConstant.INDENTIFIERS.USERDATA);

      if (storedData) {
        storedData.username = data.data.username;
        storedData.name = data.data.name;
        storedData.profile_image = data.data.profile_image;
        saveToStorage(siteConstant.INDENTIFIERS.USERDATA, storedData);
      }

      return {
        profile: data.data,
        totalPages: Math.ceil(data.data.total_post_count / 10),
        posts: data.data.posts,
        isLoading: false,
      };
    } catch (error) {
      console.error("Error fetching profile:", error);
      return rejectWithValue(error.message);
    }
  }
);

const profileSlice = createSlice({
  name: "profile",
  initialState: {
    data: null,
    totalPages: 0,
    posts: [],
    status: "idle",
    error: null,
    isLoading: true,
    userName: [],
  },
  reducers: {
    selectedUser: (state, actions) => {
      state.userName = actions.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfile.pending, (state) => {
        state.status = "loading";
        state.error = null;
        state.isLoading = false;
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload.profile;
        state.totalPages = action.payload.totalPages;
        state.posts = action.payload.posts;
        state.isLoading = false;
      })
      .addCase(fetchProfile.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.isLoading = false;
      });
  },
});
export const { selectedUser } = profileSlice.actions;
export default profileSlice.reducer;
