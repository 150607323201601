import Instagram from "src/assets/images/svg_icon/instagram-icon.svg";
import Linkedin from "src/assets/images/svg_icon/linkdin-icon.svg";
import Pinterest from "src/assets/images/svg_icon/pintrest-icon.svg";
import Facebook from "src/assets/images/svg_icon/facebook-icon.svg";
import TikTok from "src/assets/images/svg_icon/tiktok-icon.svg";
import Twitter from "src/assets/images/svg_icon/twitter-icon.svg";
import Vimeo from "src/assets/images/svg_icon/vimeo-icon.svg";
import YouTube from "src/assets/images/svg_icon/youtube-icon.svg";
import Audience from "src/assets/images/svg_icon/audience-icon.svg";
import Calender from "src/assets/images/svg_icon/calender-icon.svg";
import Location from "src/assets/images/svg_icon/location-icon.svg";
import Person from "src/assets/images/svg_icon/person-icon.svg";
import Dailymation from "src/assets/images/svg_icon/dailymotion-icon.svg";
import logo from "src/assets/images/logo.svg";
import dummyProfile from "src/assets/images/svg_icon/dummy_profile.svg";
import deletePostConfirmation from "src/assets/images/svg_icon/delete-post-confirmation.svg";
import deleteUserInfo from "src/assets/images/svg_icon/info-icon.svg";
import deleteUserBGImage from "src/assets/images/svg_icon/delete-icon-bg.svg";
import DashboardIcon from "src/assets/images/svg_icon/dashboard-icon.svg";
import AddIcon from "src/assets/images/svg_icon/add-icon.svg";
import UserIcon from "src/assets/images/svg_icon/user-icon.svg";
import LikeIcon from "src/assets/images/svg_icon/like-icon.svg";
import CommentIcon from "src/assets/images/svg_icon/comment-icon1.svg";
import TimeIcon from "src/assets/images/svg_icon/schedulepost.svg";
import LcationIcon from "src/assets/images/svg_icon/location-svg.svg";
import PlayStore from "src/assets/images/play-sore-button.svg";
import AppStore from "src/assets/images/app-store.svg";
import ReelsIcon from "src/assets/images/svg_icon/reel-icon.svg";
import Delete from "src/assets/images/svg_icon/delete-icon.svg";
import MultipleIcon from "src/assets/images/svg_icon/multiple-img-icon.svg";
import PostIcon from "src/assets/images/svg_icon/post-icon.svg";
import PostListIcon from "src/assets/images/svg_icon/post-list.svg";
import FilledLikeIcon from "src/assets/images/svg_icon/filled-like-icon.svg";
import ShareIcon from "src/assets/images/svg_icon/share-icon.svg";
import CopyLinkIcon from "src/assets/images/svg_icon/copy-Link-icon.svg";
import side_bar_arrow_icon from "src/assets/images/svg_icon/side_bar_arrow_icon.png";
import analytics_comment from "src/assets/images/svg_icon/analystics-coments.svg";
import analytics_like from "src/assets/images/svg_icon/analytics-like.svg";
import Tumblr from "src/assets/images/Channels/tumblr.svg";
import Reddit from "src/assets/images/Channels/reddit.svg";
import User from "src/assets/images/svg_icon/user-pop.svg";
import Delete_Pop from "src/assets/images/svg_icon/delete-pop.svg";
import Sign_Out from "src/assets/images/svg_icon/sign-out-icon.svg";

const SOCIAL_ICONS = {
  INSTAGRAM_ICON: Instagram,
  LINKEDIN_ICON: Linkedin,
  PINTEREST_ICON: Pinterest,
  FACEBOOK_ICON: Facebook,
  TIKTOK_ICON: TikTok,
  TWITTER_ICON: Twitter,
  VIMEO_ICON: Vimeo,
  YOUTUBE_ICON: YouTube,
  AUDIANCE_ICON: Audience,
  CALENDER_ICON: Calender,
  LOCATION_ICON: Location,
  PERSON_ICON: Person,
  DAILYMOTION_ICON: Dailymation,
  YOOIILOGO: logo,
  DUMMY_PROFILE: dummyProfile,
  DELETE_POST_CONFIRMATION: deletePostConfirmation,
  DELETE_USER_INFO: deleteUserInfo,
  DELETE_USER_BG: deleteUserBGImage,
  LIKE_ICON: LikeIcon,
  COMMENT_ICON: CommentIcon,
  LOCATION_ICONN: LcationIcon,
  PLAY_STORE: PlayStore,
  APP_STORE: AppStore,
  REELS_ICON: ReelsIcon,
  DELETE: Delete,
  MULTIPLE_IMG_ICON: MultipleIcon,
  POST_ICON: PostIcon,
  POST_LIST_ICON: PostListIcon,
  FILLED_LIKE_ICON: FilledLikeIcon,
  SHARE_ICON: ShareIcon,
  COPY_LINK_ICON: CopyLinkIcon,
  SIDE_BAR_ARROW_ICON: side_bar_arrow_icon,
  ANALYTICS_LIKE: analytics_like,
  ANALYTICS_COMMENT: analytics_comment,
  TUMBLR_ICON: Tumblr,
  REDDIT_ICON: Reddit,
  USER: User,
  DELETE: Delete_Pop,
  SIGN_OUT: Sign_Out,
};
const ICONS = {
  Dashboard_Icon: DashboardIcon,
  Add_Icon: AddIcon,
  User_Icon: UserIcon,
  Time_Icon: TimeIcon,
};
const CHANNEL_LIST = [
  {
    id: 1,
    name: "Instagram",
    icon: Instagram,
    iconColor: "#E1306C",
    selected: false,
    color: "bg-pink-100",
  },
  // {
  //   id: 2,
  //   name: "Facebook",
  //   icon: Facebook,
  //   iconColor: "#3b5998",
  //   selected: false,
  //   color: "bg-blue-100",
  // },
  // {
  //   id: 3,
  //   name: "YouTube",
  //   icon: YouTube,
  //   iconColor: "#FF0000",
  //   selected: false,
  //   color: "bg-red-100",
  // },
  {
    id: 2,
    name: "Pinterest",
    icon: Pinterest,
    iconColor: "#E60023",
    selected: false,
    color: "bg-[#FFD6D6]",
  },
  {
    id: 3,
    name: "LinkedIn",
    icon: Linkedin,
    iconColor: "#0077b5",
    selected: false,
    color: "bg-[#D1E8FF]",
  },
  // {
  //   id: 6,
  //   name: "Twitter",
  //   icon: Twitter,
  //   iconColor: "#000000",
  //   selected: false,
  //   color: "bg-gray-200",
  // },
  {
    id: 4,
    name: "Vimeo",
    icon: Vimeo,
    iconColor: "#1ab7ea",
    selected: false,
    color: "bg-[#C9F1FF]",
  },

  {
    id: 5,
    name: "tumblr",
    icon: Tumblr,
    iconColor: "#1ab7ea",
    selected: false,
    color: "bg-[#D6E6FF]",
  },

  {
    id: 6,
    name: "reddit",
    icon: Reddit,
    iconColor: "#1ab7ea",
    selected: false,
    color: "bg-[#FFD3C3]",
  },
  // {
  //   id: 8,
  //   name: "TikTok",
  //   icon: TikTok,
  //   iconColor: "#000000",
  //   selected: false,
  //   color: "bg-black",
  // },
  // {
  //   id: 9,
  //   name: "Dailymotion",
  //   icon: Dailymation,
  //   iconColor: "#1ab7ea",
  //   selected: false,
  //   color: "bg-blue-400",
  // },
];

const INDENTIFIERS = {
  USERDATA: "userdata",
  acceptlanguage: "acceptlanguage",
  currentTab: "currentactivetab",
};

export default {
  SOCIAL_ICONS,
  CHANNEL_LIST,
  INDENTIFIERS,
  ICONS,
};
