// src/components/SidebarLayout.jsx
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Outlet } from "react-router-dom";
import siteConstant from "src/helpers/constant/siteConstant";

const SidebarLayout = () => {
    const location = useLocation();
    const [currentTab, setCurrentTab] = useState('/privacy-policy');

    const navigate = useNavigate();

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const isMobile = window.innerWidth <= 768;  // You can use Tailwind's screen sizes too

    const handleNavigate = (link) => {
        setIsSidebarOpen(false);
        navigate(link);
    }

    useEffect(() => {
        setCurrentTab(location.pathname)
        console.log(location.pathname)
    }, [location.pathname])

    return (
        <div className={`flex h-screen ${isSidebarOpen ? "overflow-hidden" : ""}`}>
            {/* Sidebar */}
            <aside
                className={`fixed top-0 left-0 h-screen z-50 ${isSidebarOpen ? "bg-white" : "bg-transparent"
                    } transform transition-transform ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"
                    } md:translate-x-0 w-64 md:w-64`}
            >
                <div className="flex flex-col h-full">
                    {/* Logo */}
                    <div className="flex items-center justify-center flex-col p-4">
                        <img
                            src={siteConstant.SOCIAL_ICONS.YOOIILOGO}
                            alt="Yooii"
                            className="h-14 w-14 md:h-12 md:w-12"
                        />
                        <p
                            className={`text-xl font-semibold font-Montserrat transition-all duration-300 ${isSidebarOpen || "hidden md:block"
                                }`}
                        >
                            YOOII
                        </p>
                    </div>

                    {/* Menu */}
                    <nav className="flex-grow mt-4 font-Montserrat">
                        <ul className="space-y-2 mx-4">
                            <li>
                                <p
                                    onClick={() => handleNavigate("/privacy-policy")}
                                    className={`block cursor-pointer px-4 py-2 hover:text-red-500 hover:bg-gray-200 rounded-md ${currentTab === "/privacy-policy"
                                        ? "text-red-500"
                                        : "text-gray-700"
                                        }`}
                                >
                                    Privacy Policy
                                </p>
                            </li>
                            <li>
                                <p
                                    onClick={() => handleNavigate("/terms-of-use")}
                                    className={`block cursor-pointer px-4 py-2 hover:text-red-500 hover:bg-gray-200 rounded-md ${currentTab === "/terms-of-use"
                                        ? "text-red-500"
                                        : "text-gray-700"
                                        }`}
                                >
                                    Terms and Conditions
                                </p>
                            </li>
                            <li>
                                <p
                                    onClick={() => handleNavigate("/contact-us")}
                                    className={`block cursor-pointer px-4 py-2 hover:text-red-500 hover:bg-gray-200 rounded-md ${currentTab === "/contact-us"
                                        ? "text-red-500"
                                        : "text-gray-700"
                                        }`}
                                >
                                    Contact Us
                                </p>
                            </li>
                        </ul>
                    </nav>
                </div>
            </aside>

            {/* Main Content */}
            <main
                className={`flex-grow items-center md:ml-64 transition-all duration-300 ${isSidebarOpen ? "md:ml-64" : ""
                    }`}
            >
                {/* Hamburger Button */}


                {/* Mobile Navbar Tab Switcher */}
                <div className={`md:hidden bg-transparent max-w-full sm:max-w-auto backdrop-blur-lg fixed left-0 w-full z-99 flex justify-around py-3  outline-none md:ml-64 transition-all duration-300 ${isSidebarOpen ? "md:ml-64" : ""
                    }`}>
                    <div className={`md:hidden z-50 h-[24px] ml-2`}>
                        <button
                            onClick={toggleSidebar}
                            className="text-gray-700 focus:outline-none"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={2}
                                stroke="currentColor"
                                className="w-6 h-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M3 6h18M3 12h18m-6 6h6"
                                />
                            </svg>
                        </button>
                    </div>
                    <div className='flex justify-around w-[80%] gap-2'>
                        <button
                            className={`text-[12px] font-medium px-5 ${currentTab === "/privacy-policy"
                                ? "text-red-500 border-b-2 border-red-500"
                                : "text-gray-700"
                                }`}
                            onClick={() => navigate("/privacy-policy")}
                            style={{ paddingBottom: "12px" }}
                        >
                            Privacy Policy
                        </button>
                        <span className="w-[1px] bg-red-500 block"></span>
                        <button
                            className={`text-[12px] font-medium px-5 ${currentTab === "/terms-of-use"
                                ? "text-red-500 border-b-2 border-red-500"
                                : "text-gray-700"
                                }`}
                            onClick={() => navigate("/terms-of-use")}
                            style={{ paddingBottom: "12px" }}
                        >
                            Terms & Conditions
                        </button>
                    </div>
                </div>


                {/* Page Content */}
                <div
                    className="md:mt-1 sm:mt-2"
                    style={{
                        marginTop: isMobile && currentTab !== "/" ? "80px" : "80px", // Added top padding to prevent overlap
                    }}
                >
                    <Outlet />
                </div>
            </main>

            {/* Backdrop for mobile view */}
            {isSidebarOpen && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 z-40 md:hidden"
                    onClick={toggleSidebar}
                />
            )}
        </div>
    );
};


export default SidebarLayout;
